import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./../../assets/Logopit_1673179781121.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as backend from "../../config";

function StudentLogin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isSignup, setIsSignup] = useState(false);
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confPass, setConfPass] = useState("");

  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
  };

  const handleChange = () => {};
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangeConfirmPassword = (e) => {
    setConfPass(e.target.value);
  };
  const handleChangePass = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(email);
    // console.log(password);
    // console.log(name);
    // console.log(confPass);
    if (isSignup) {
      if (password !== confPass) {
        // console.log("Password doesn't match");
        toast.error("Password not matching !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (email === "" || name === "") {
        // alert("Enter Details");
        toast.warning("Name and Email is required !", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else {
        await fetch(backend.backend + "register", {
          method: "POST",
          crossDomain: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({
            name,
            email,
            password,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data, "userRegister");
            if (data.success) {
              // console.log("success!");
              toast.success("Account Created !", {
                position: toast.POSITION.TOP_RIGHT,
              });
              window.localStorage.setItem("token", data.token);
              window.localStorage.setItem("type", data.user.role);
              window.localStorage.setItem("email", data.user.email);
              window.location.href = "/company";
            } else
              toast.error("Something Went Wrong !", {
                position: toast.POSITION.TOP_RIGHT,
              });
          });
      }
    } else {
      fetch(backend.backend + "login", {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          // console.log(data.token)
          if (data.success) {
            toast.success("Success, loggin in...", {
              position: toast.POSITION.TOP_RIGHT,
            });
            window.localStorage.setItem("token", data.token);
            window.localStorage.setItem("type", data.user.role);
            window.localStorage.setItem("email", data.user.email);
            // setTimeout(() => {
            window.location.href = "/company";
            //   navigate("/company");
            // }, 1000);
          } else {
            toast.error("Invalid Credentials !", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
    }
  };
  const forgotPass = (e) => {
    window.location.href = "/studentResetPassword";
  };
  return (
    //  01112002
    <div
      style={{ flex: 1 }}
      class="min-h-screen bg-gradient-to-r from-bg-blue via-purple-500 to-bg-purple flex flex-col justify-center"
    >
      {/* <section class= "bg-center justify-center text-center text-2xl pb-3 text-white font-bold  ">
            {isSignup ? "Sign Up and get Referred" : "Welcome Back Login In"}
            </section> */}
      <div class="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
        <div class="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
          <form class="px-5" onSubmit={handleSubmit}>
            <div class="chat-notification-logo-wrapper flex justify-center">
              <img
                class="chat-notification-logo h-1/4 w-1/4"
                src={logo}
                alt="ChitChat Logo"
              />
            </div>
            <label class="font-semibold text-sm text-gray-600 pb-1 block">
              Email
            </label>
            <input
              type="email"
              class="border rounded-lg px-3 py-2 mt-1 mb-2 text-sm w-full"
              handleChange={handleChange}
              onChange={handleChangeEmail}
              value={email}
            />
            {isSignup && (
              <>
                <label class="font-semibold text-sm text-gray-600 pb-1 block">
                  Full Name
                </label>
                <input
                  type="text"
                  handleChange={handleChange}
                  onChange={handleChangeName}
                  class="border rounded-lg px-3 py-2 mt-1 mb-2 text-sm w-full"
                  value={name}
                />
              </>
            )}

            <label class="font-semibold text-sm text-gray-600 pb-1 block">
              Password
            </label>
            <input
              type="password"
              class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
              handleChange={handleChange}
              onChange={handleChangePass}
              value={password}
            />

            {isSignup && (
              <>
                <label class="font-semibold text-sm text-gray-600 pb-1 block">
                  Confirm Password
                </label>
                <input
                  type="password"
                  handleChange={handleChange}
                  onChange={handleChangeConfirmPassword}
                  value={confPass}
                  class="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full"
                />
              </>
            )}

            <button
              // type="submit"
              class="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
            >
              <span class="inline-block mr-2">
                {isSignup ? "Sign Up " : "Sign in"}
              </span>
              {/* add icon */}
            </button>
          </form>
          <div class="py-5">
            {!isSignup && (
              <>
                <div class="text-center whitespace-nowrap">
                  <button
                    class="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-bg-stone-700 text-gray-600"
                    onClick={forgotPass}
                  >
                    <span class="inline-block ml-1">Forgot Password</span>
                  </button>
                </div>
              </>
            )}
            <div class="text-center whitespace-nowrap">
              <button
                onClick={switchMode}
                class="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-600"
              >
                <span class="inline-block ml-1">
                  {isSignup
                    ? "Already Have an account? Sign In "
                    : "Don't have an account! Sign Up"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentLogin;
