import React, { useState } from "react";
import ColdEmailTemplates from "./ColdEmailTemplates";
import ResumeTemplates from "./ResumeTemplates";
import CoverLettersTemplates from "./CoverLettersTemplates";

const Templates = () => {
  const [openTab, setOpenTab] = useState(1);

  return (
    <div className="bg-gray-200 h-screen">
      <div className="flex flex-wrap pt-20">
        <div className="w-full">
          <div className="container pl-10 pr-10">
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-[#8A3B8C]"
                      : "text-[#2D498E] bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Resume
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-white bg-[#8A3B8C]"
                      : "text-[#2D498E] bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Cover Letters
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 3
                      ? "text-white bg-[#8A3B8C]"
                      : "text-[#2D498E] bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Email Templates
                </a>
              </li>
            </ul>
          </div>

          {/* _____ */}
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded bg-gray-200 h-full">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <ResumeTemplates />
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <CoverLettersTemplates />
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <ColdEmailTemplates />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Templates;
